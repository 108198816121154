import { useCallback, useEffect, useState } from 'react'
import { getReferral, Referral } from './Sanity'

const useReferral = (type: string) => {
  const [referral, setReferral] = useState<Referral>()
  const getReferralData = useCallback(async () => {
    const data = (await getReferral(type)) as Referral
    setReferral(data)
  }, [type])

  useEffect(() => {
    getReferralData()
  }, [])

  return {
    referral,
  }
}

export default useReferral
