import React, { FC, forwardRef, useMemo } from 'react'
import { graphql, PageProps } from 'gatsby'
import { useQueryParam, StringParam } from 'use-query-params'
import { withPreview } from 'gatsby-source-prismic'
import { FixedSizeGrid as Grid, GridChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ReactWindowScroller } from 'react-window-scroller'
import moment from 'moment-timezone'

import Layout from '../components/Layout'
import '../components/base.css'
import {
  ArchiveQuery,
  SanityEvent,
  Maybe,
  SanityImageAsset,
} from '../../graphql-types'
import extractGlobals from '../lib/extractGlobals'
import EmailReferralForm from '../components/EmailReferralForm'
import { SectionContent, SectionTitle } from '../components/FrontMatter'
import { mq, spaces, styled, typography } from '../styles'
import useReferral from '../lib/useReferral'
import SanityFluidImage, {
  getSanitySrcSet,
} from '../components/SanityFluidImage'
import locationIcon from '../images/locationIcon.png'
import placesIcon from '../images/PlacesIcon.png'
import sunsetIcon from '../images/sunset.png'

const GUTTER_SIZE = 25

const Archive: FC<PageProps<ArchiveQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)
  const [type, setType] = useQueryParam('c', StringParam)

  const { referral } = useReferral(type ? type : 'default')

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <Section>
        <SeasonTitle>Winter Season 2021</SeasonTitle>

        <ReactWindowScroller isGrid>
          {({ ref, outerRef, style, onScroll }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <Grid
                  ref={ref}
                  outerRef={outerRef}
                  style={style}
                  width={window.innerWidth}
                  height={window.innerHeight}
                  columnCount={3}
                  columnWidth={width / 4}
                  rowHeight={(width / 4) * 1.3 + GUTTER_SIZE}
                  rowCount={
                    data.winter2021Events.nodes.length > 0
                      ? data.winter2021Events.nodes.length / 3
                      : 1
                  }
                  onScroll={onScroll}
                  innerElementType={innerElementType}
                  itemData={data.winter2021Events.nodes}>
                  {(props) => <EventCard {...props} />}
                </Grid>
              )}
            </AutoSizer>
          )}
        </ReactWindowScroller>

        <SeasonTitle>Spring Season 2021</SeasonTitle>
        <ReactWindowScroller isGrid>
          {({ ref, outerRef, style, onScroll }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <Grid
                  ref={ref}
                  outerRef={outerRef}
                  style={style}
                  width={window.innerWidth}
                  height={window.innerHeight}
                  columnCount={3}
                  columnWidth={width / 4}
                  rowHeight={(width / 4) * 1.44 + GUTTER_SIZE}
                  rowCount={
                    data.spring2021Events.nodes.length > 0
                      ? data.spring2021Events.nodes.length / 3
                      : 1
                  }
                  onScroll={onScroll}
                  innerElementType={innerElementType}
                  itemData={data.spring2021Events.nodes}>
                  {(props) => <EventCard {...props} />}
                </Grid>
              )}
            </AutoSizer>
          )}
        </ReactWindowScroller>
      </Section>
    </Layout>
  )
}

const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      width: style.width + window.innerWidth / 8,
      paddingLeft: GUTTER_SIZE,
      paddingTop: GUTTER_SIZE,
    }}
    {...rest}
  />
))

export default withPreview(Archive)

const Section = styled.div(
  {
    flex: '1 0 auto',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  mq({
    paddingTop: spaces.large,
    paddingBottom: spaces.large,
  }),
)

const WindowScrollerWrapper = styled.div({
  flex: '1 1 auto',
})

const SeasonTitle = styled.h2(
  typography.largeSerif,
  mq({
    textAlign: 'center',
    marginBottom: spaces.medium,
  }),
)

type EventType = {
  data: (Pick<
    SanityEvent,
    'startTime' | 'name' | 'description' | 'endTime' | 'location' | 'type'
  > & {
    image?: Maybe<{
      asset?: Maybe<Pick<SanityImageAsset, 'gatsbyImageData' | 'url'>>
    }>
  })[]
} & GridChildComponentProps

const EventCard: FC<EventType> = ({ data, columnIndex, rowIndex, style }) => {
  const item = data[rowIndex * 3 + columnIndex]
  const rawStartTime = moment(item.startTime).tz(moment.tz.guess())
  const date = rawStartTime.format('MMM D dddd')
  const startTime = rawStartTime.format('h:mma')
  const rawEndTime = moment(item.endTime).tz(moment.tz.guess())
  const endTime = rawEndTime.format('h:mma') + ' ' + rawEndTime.format('z')

  const [title, typeTitle] = useMemo(() => {
    let eventName, eventType
    if (item.name.split(':').length > 1) {
      eventName = item.name.split(':')[1].trim()
      eventType = item.name.split(':')[0].toUpperCase()
    } else {
      eventName = item.name
      if (item.type === 'ambient') {
        eventType = 'PLACE'
      } else {
        eventType = 'PERFORMANCE'
      }
    }
    return [eventName, eventType]
  }, [item.name, item.type])

  const isSunset = typeTitle === 'SUNSET'

  return (
    <div
      style={{
        ...style,
        display: 'flex',
        flex: 1,
        left: style.left + GUTTER_SIZE / 2 + window.innerWidth / 8,
        top: style.top + GUTTER_SIZE,
        width: style.width - GUTTER_SIZE,
        height: style.height - GUTTER_SIZE,
      }}>
      <CardContainer>
        <CardTextContainer>
          {isSunset && <TypeImage src={sunsetIcon} alt={'sunset icon'} />}
          {typeTitle === 'PLACE' && (
            <TypeImage src={placesIcon} alt={'places icon'} />
          )}
          <CardTitle>{item.name}</CardTitle>

          <LocationContainer>
            {item.location && (
              <>
                <LocationIconImage src={locationIcon} alt={'location icon'} />
                <CardText>{item.location}</CardText>
              </>
            )}
          </LocationContainer>

          <CardText>{date}</CardText>
          <CardText>{`${startTime} – ${endTime}`}</CardText>

          <CardText>{item.description}</CardText>
        </CardTextContainer>
        <CardImageContainer>
          {item.image?.asset && (
            <CardImage
              src={item.image.asset.url}
              srcSet={getSanitySrcSet(item.image.asset.url, 400)}
              sizes={'(max-width: 800px) 100%, 100%'}
            />
          )}
        </CardImageContainer>
      </CardContainer>
    </div>
  )
}

const CardContainer = styled.div({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  borderRadius: 15,
  border: 'solid 1px black',
})

const CardTextContainer = styled.div(
  {
    display: 'flex',
    flex: 6,
    flexDirection: 'column',
    borderBottom: 'solid 1px black',
    textAlign: 'center',
  },
  mq({
    paddingLeft: spaces.small,
    paddingRight: spaces.small,
  }),
)

const CardTitle = styled.h3(
  typography.smallSerif,
  mq({
    paddingTop: spaces.medium,
    textAlign: 'center',
    lineHeight: 1,
  }),
)

const CardText = styled.div(
  typography.tinyMono,
  mq({
    paddingBottom: [0, 0, 0],
  }),
)

const LocationContainer = styled.div(
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mq({
    paddingBottom: spaces.small,
  }),
)

const TypeImage = styled.img({
  width: 25,
  position: 'absolute',
  top: 10,
  right: 10,
})

const LocationIconImage = styled.img({
  width: 12,
  marginRight: 10,
  objectFit: 'contain',
})

const CardImageContainer = styled.div({
  display: 'flex',
  flex: 5,
  flexDirection: 'column',
  overflow: 'hidden',
})

const DecoImage = styled(SanityFluidImage)(
  mq({
    maxWidth: '100%',
    width: 385,
    margin: '15px auto',
    marginBottom: spaces.large,
  }),
)

const CardImage = styled.img({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  borderBottomLeftRadius: 15,
  borderBottomRightRadius: 15,
})

export const pageQuery = graphql`
  query ArchiveScroll {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    winter2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2020-12-21T00:00:01.815Z"
          lt: "2021-03-19T00:00:01.815Z"
        }
      }
      sort: { order: ASC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
    spring2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2021-03-19T00:00:01.815Z"
          lt: "2021-06-21T00:00:01.815Z"
        }
      }
      sort: { order: ASC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
    summer2021Events: allSanityEvent(
      filter: {
        startTime: {
          gte: "2021-06-21T00:00:01.815Z"
          lt: "2021-09-22T00:00:01.815Z"
        }
      }
      sort: { order: ASC, fields: startTime }
    ) {
      nodes {
        startTime
        name
        description
        endTime
        id
        location
        type
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            url
          }
        }
      }
    }
  }
`
